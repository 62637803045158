import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Pantry from "../models/Pantry";

export const getPantries = createAsyncThunk(
  "pantry/getPantries",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/canteen?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addPantry = createAsyncThunk(
  "pantry/addPantry",
  async (pantry: Pantry, { rejectWithValue }) => {
    try {
      const response = await API.post("canteen", pantry);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePantry = createAsyncThunk(
  "pantry/updatePantry",
  async (pantry: Pantry, { rejectWithValue }) => {
    try {
      const response = await API.put(`canteen/${pantry._id}`, pantry);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deletePantry = createAsyncThunk(
  "pantry/deletePantry",
  async (_id: string) => {
    try {
      const response = await API.delete(`canteen/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const submitOrder = createAsyncThunk(
  "pantry/submitOrder",
  async (orderData: any, { rejectWithValue }) => {
    try {
      const response = await API.post("c-transections", orderData);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getVendorCanteenTransactionsByDay = createAsyncThunk(
  "pantry/getVendorCanteenTransactionsByDay",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/c-transections/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
