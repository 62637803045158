import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  addPantry,
  getPantries,
  updatePantry,
  getVendorCanteenTransactionsByDay,
} from "../services/pantryApi";
import Pantry from "../models/Pantry";

interface PantryState {
  loading: boolean;
  error: string | null;
  pantries: Pantry[];
  pantryAddLoading: boolean;
  pantryAddError: boolean | false;
  pantryTransactions: any[];
}

const initialState = {
  loading: false,
  error: null,
  pantries: [],
  pantryAddLoading: false,
  pantryAddError: false,
  pantryTransactions: [],
} as PantryState;

const pantrySlice = createSlice({
  name: "pantries",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPantries.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPantries.fulfilled,
        (state, action: PayloadAction<Pantry[]>) => {
          state.loading = false;
          state.error = null;
          state.pantries = action.payload;
        }
      )
      .addCase(getPantries.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addPantry.pending, (state, action) => {
        state.pantryAddLoading = true;
        state.pantryAddError = false;
        state.error = null;
      })
      .addCase(addPantry.fulfilled, (state, action: PayloadAction<Pantry>) => {
        state.pantryAddLoading = false;
        state.pantryAddError = false;
        state.error = null;
        state.pantries?.unshift(action.payload);
      })
      .addCase(addPantry.rejected, (state, action: PayloadAction<any>) => {
        state.pantryAddLoading = false;
        state.pantryAddError = true;
        state.error = action.payload;
      })

      .addCase(updatePantry.pending, (state, action) => {
        state.pantryAddLoading = true;
        state.pantryAddError = false;
        state.error = null;
      })
      .addCase(
        updatePantry.fulfilled,
        (state, action: PayloadAction<Pantry>) => {
          state.pantryAddLoading = false;
          state.pantryAddError = false;
          state.error = null;

          state.pantries = state.pantries?.map((pantry) =>
            pantry._id === action.payload._id ? action.payload : pantry
          );
        }
      )
      .addCase(updatePantry.rejected, (state, action: PayloadAction<any>) => {
        state.pantryAddLoading = false;
        state.pantryAddError = true;
        state.error = action.payload;
      })

      .addCase(getVendorCanteenTransactionsByDay.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorCanteenTransactionsByDay.fulfilled,
        (state, action: PayloadAction<Pantry[]>) => {
          state.loading = false;
          state.error = null;
          state.pantryTransactions = action.payload;
        }
      )
      .addCase(
        getVendorCanteenTransactionsByDay.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default pantrySlice.reducer;
