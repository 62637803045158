import { configureStore, combineReducers } from "@reduxjs/toolkit";
import vendorSlice from "./vendorSlice";
import planSlice from "./planSlice";
import authSlice from "./authSlice";
import addonSlice from "./addonSlice";
import couponSlice from "./couponSlice";
import salesPersonSlice from "./salesPersonSlice";
import customerSlice from "./customerSlice";
import checkoutSlice from "./checkoutSlice";
import playersBucketSlice from "./playersBucketSlice";
import arcadeMachineSlice from "./arcadeMachineSlice";
import machineCoinSlice from "./machineCoinSlice";
import referralSlice from "./referralSlice";
import transactionSlice from "./transactionSlice";
import appliedCouponsSlice from "./appliedCouponsSlice";
import pantrySlice from "./pantrySlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  vendors: vendorSlice,
  selectedVendor: vendorSlice,
  plans: planSlice,
  userInfo: authSlice,
  addons: addonSlice,
  coupons: couponSlice,
  salesPersons: salesPersonSlice,
  customers: customerSlice,
  checkoutPlans: checkoutSlice,
  customerSoftplayPlans: checkoutSlice,
  customerPlaystationPlans: checkoutSlice,
  customerComboPlans: checkoutSlice,
  vendorComboCheckouts: checkoutSlice,
  vendorSoftplayCheckouts: checkoutSlice,
  vendorPlaystationCheckouts: checkoutSlice,
  vendorArcadegamesCheckouts: checkoutSlice,
  playersBuckets: playersBucketSlice,
  arcadeMachines: arcadeMachineSlice,
  machineCoins: machineCoinSlice,
  machineCoinsReport: machineCoinSlice,
  referrals: referralSlice,
  vendorTransactions: transactionSlice,
  vendorCustomerTransactions: transactionSlice,
  vendorComboExtraTransactions: transactionSlice,
  vendorSoftplayExtraTransactions: transactionSlice,
  vendorPlaystationExtraTransactions: transactionSlice,
  vendorArcadeExtraTransactions: transactionSlice,
  vendorSalesReport: vendorSlice,
  customerCheckouts: customerSlice,
  customerCheckoutHistory: customerSlice,
  appliedCOupons: appliedCouponsSlice,
  pantries: pantrySlice,
  pantryTransactions: pantrySlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
