import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const getVendorTransactions = createAsyncThunk(
  "transactions/getVendorTransactions",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/transaction?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}&populate=customer`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVendorCustomerTransactions = createAsyncThunk(
  "transactions/getVendorCustomerTransactions",
  async (getParams: any) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        vendor: getParams.vendor,
        customer: getParams.customer,
      })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/transaction?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVendorComboExtraTransactions = createAsyncThunk(
  "transactions/getVendorComboExtraTransactions",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=combo`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorSoftplayExtraTransactions = createAsyncThunk(
  "transactions/getVendorSoftplayExtraTransactions",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=softplay`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorPlaystationExtraTransactions = createAsyncThunk(
  "transactions/getVendorPlaystationExtraTransactions",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=playstation`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorArcadeExtraTransactions = createAsyncThunk(
  "transactions/getVendorArcadeExtraTransactions",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=arcadegames`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorCustomerCheckoutByTransaction = createAsyncThunk(
  "transactions/getVendorCustomerCheckoutByTransaction",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        transaction: getParams.transId,
      })
    );
    try {
      const response = await API.get(`/checkout?filter=${URIDecodedVendor}`);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorCustomerTransactionsByBillNumber = createAsyncThunk(
  "transactions/getVendorCustomerTransactionsByBillNumber",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        billNumber: getParams.transId,
      })
    );
    try {
      const response = await API.get(`/transaction?filter=${URIDecodedVendor}`);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVendorCustomerTransaction = createAsyncThunk(
  "transactions/updateVendorCustomerTransaction",
  async (transactionData: any, { rejectWithValue }) => {
    try {
      const response = await API.put(
        `transaction/${transactionData._id}`,
        transactionData
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getVendorAllTransactions = createAsyncThunk(
  "transactions/getVendorAllTransactions",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&populate=customer`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorSalesByDate = createAsyncThunk(
  "transactions/getVendorSalesByDate",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/transaction/get-by-daterange?vendorId=${getParams.vendorId}&startDate=${getParams.fromDate}&endDate=${getParams.toDate}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
